#banner{padding:50px 0 0;}
#banner .banner-content{
    background-image:url('../../../assets/images/Toppy-Illustration_HD.png');
    background-position:100% 100%;background-repeat: no-repeat;
    background-size:contain;
    padding-bottom:100px;
}
#banner h1{margin:0;}
#banner .banner-holder{padding:0 10px 0 0;max-width:950px;}
#banner .btns-holder{padding:40px 0 0;}
#banner .btns-holder .MuiButton-root{margin:0 35px 30px 0;}
#banner .MuiButton-root{padding:15px 30px !important;border-radius:8px !important;font-size:18px !important;line-height:10px !important;text-transform: unset !important;} 
#banner .MuiButton-containedPrimary{background:#db9137 !important;color:#23232D !important;border:2px solid #db9137 !important;}
#banner .MuiButton-containedPrimary:hover{background:transparent !important;color:#db9137 !important;border:2px solid #db9137 !important;} 
#banner .MuiButton-containedSecondary{background-color:transparent !important;color:#fff !important;border:2px solid #fff !important;}
#banner .MuiButton-containedSecondary:hover{background-color:#fff !important;color:#23232D !important;border:2px solid #fff !important;} 

#banner .value-locked-area{padding:30px 0;font-size:16px;line-height:19px;font-weight:300;background:#86be29;}
#banner .value-locked-area h2{margin:0 0 10px;}
#banner .value-locked-area p{margin:0;}
@media (max-width: 991px) {
    #banner .btns-holder{padding:20px 0 0;display: flex;flex-flow: column;}
    #banner .btns-holder .MuiButton-root{margin: 0 25px 20px 0;width: 200px; max-width: 100%;}
    #banner .banner-content{background-size: contain;padding-bottom: 60px;}
#banner .value-locked-area{padding:20px 0;font-size:15px;}
}
@media (max-width: 767px) {
    #banner .value-locked-area{font-size:14px;}
#banner{padding:40px 0 0;}
}
@media (max-width: 599px) {
#banner{padding:30px 0 0;}
#banner h1{margin-bottom: 5px;}
    #banner .btns-holder .MuiButton-root{margin: 0 15px 10px 0;}
    #banner .banner-content{padding-bottom: 40px;}
}