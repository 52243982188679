#header .container{padding-top:42px;padding-bottom:42px;border-bottom:1px solid #fff;}
.logo{width:277px;}
.social-links{list-style:none;}
.social-links li{background-image:url('../../../assets/images/social-media-icons.png');margin:0 0 0 30px;}
.social-links li:hover{background-image:url('../../../assets/images/social-media-icons-hover.png');}
.social-links li.plane{width:25px;height:21px;background-position: 0 -2px;}
.social-links li.facebook{width:13px;height:24px;background-position: -51px 0;}
.social-links li.twitter{width:25px;height:20px;background-position: -91px -2px;}
.social-links li.mail{width:25px;height:20px;background-position: -143px -2px;}
.social-links li.github{width:25px;height:20px;background-position: -195px -2px;}
.social-links li a{display:block;height:100%;text-indent:-9999px;}

@media (max-width: 991px) {
	#header .container{
		padding-top: 20px;
		padding-bottom: 20px;
	}
}
@media only screen and (max-width: 767px){
	.logo{width:230px;}
	.social-links li{margin:0 0 0 20px;}
}
@media only screen and (max-width: 599px){
	.logo-container{text-align: center;}
	.logo{margin: 0 auto 25px;}
	#header .container{padding-top:20px;padding-bottom:20px;}
	.social-links{justify-content: center !important;}	
}