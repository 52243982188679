body{background:#1f1f28;font-family: 'Roboto';}
@media (min-width: 1280px){
	.MuiContainer-maxWidthLg {max-width: 1330px;}
}
/* Reset Styles */
h1,h2,h3{margin-top:0;}
h1{font-size:91px;line-height:95px;font-weight: 300;}
h2{font-size:32px;line-height:36px;font-weight: 500;}
h3{font-size:24px;font-weight: 500;}
h4{font-size: 16px;}
p{margin:0 0 15px;}
/* Custom Classes */
.text-white{color:#fff;}
.text-blue{color:#23232D;}
.text-uppercase{text-transform: uppercase;}
.list-unstyled{list-style:none;padding:0;margin:0;}
.d-inline-block{display:inline-block;}
.align-top{vertical-align:top;}
.img-fluid{max-width: 100%;height: auto;}
/* .MuiButton-root{padding:15px 30px !important;border-radius:8px !important;font-size:24px !important;line-height:27px !important;text-transform: unset !important;} */
/* .MuiButton-containedPrimary{background:#db9137 !important;color:#23232D !important;border:2px solid #db9137 !important;}
.MuiButton-containedPrimary:hover{background:transparent !important;color:#db9137 !important;border:2px solid #db9137 !important;} */
/* .MuiButton-containedSecondary{background-color:transparent !important;color:#fff !important;border:2px solid #fff !important;}
.MuiButton-containedSecondary:hover{background-color:#fff !important;color:#23232D !important;border:2px solid #fff !important;} */
.MuiButton-containedSecondary.bg-white{background-color: #fff !important;color: #23232D !important;border-color: #23232D !important;}
.MuiButton-containedSecondary.bg-white:hover{background-color: #23232D !important;color: #fff !important;border-color: #23232D !important;}
.MuiContainer-maxWidthLg.main-container{max-width:890px;}
.section-heading{margin:0 0 5px;}
.section-spacing{padding: 50px 0;}
.footer-btns-holder button{margin:0 15px 30px;}
.custom-slider button{background-color: transparent !important;opacity: 1 !important;margin: 0 !important;padding: 0 !important;}
.custom-slider button[aria-label="Previous"] {display:none;transform: rotate(180deg);}

@media (max-width: 991px) {
h1 {font-size: 75px;line-height: 80px;}
h2{font-size:28px;line-height:32px;}
h3{font-size:22px;}
h4{font-size: 14px;}
.MuiButton-root{padding:12px 25px !important;font-size:20px !important;line-height:25px !important}
.section-spacing{padding: 35px 0;}
}
@media (max-width: 767px) {
h1 {font-size: 60px;line-height: 65px;}
h2{font-size:25px;line-height:30px;}
h3{font-size:20px;}
.MuiButton-root{padding:12px 20px !important;font-size:18px !important;line-height:22px !important}
.section-spacing{padding: 25px 0;}
.MuiGrid-spacing-xs-7 > .MuiGrid-item{	padding: 15px !important;}
.MuiGrid-spacing-xs-7 {    width: 100% !important;    margin: 0 !important;}
.footer-btns-holder button { margin: 10px 15px 20px;}
}
@media (max-width: 599px) {
	h1 {font-size: 40px;line-height: 45px;}
	h2{font-size:20px;line-height:25px;}
	h3{font-size:18px;}
	.MuiButton-root{padding:10px 15px !important;font-size:16px !important;line-height:20px !important}
	.section-spacing{padding: 20px 0;}
	.MuiGrid-spacing-xs-7 > .MuiGrid-item{	padding: 12px !important;}
	.footer-btns-holder{display: flex;flex-flow: column;}
	.footer-btns-holder button { margin: 10px;width: 250px;margin: 0 auto 10px;}
}