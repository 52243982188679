#protocol{background-image: url('../../../assets/images/Grass.png');background-position: 90% bottom;background-repeat: no-repeat;}
#protocol .protocol-box{background:#fff;color:#23232d;padding:0 0 25px;height:100%;max-width: 400px;margin: 0 auto;}
#protocol .protocol-box:hover {box-shadow: 0px 0px 12px 0px #ababab;transform: scale(1.05);}
#protocol .protocol-banner{margin:0 0 30px; cursor:pointer;}
#protocol .protocol-text-block{padding:0 15px;font-size:14px;line-height: 24px;font-weight: 300;}
#protocol .protocol-text-block p{margin:0;}
#protocol .protocol-text-block ol{margin:0;padding:25px 0 0 15px;}
#protocol .protocol-text-block ol li{margin:0 0 20px;}
#protocol .MuiButton-root{padding:15px 30px !important;border-radius:8px !important;font-size:18px !important;line-height:10px !important;text-transform: unset !important;} 
#protocol .MuiButton-containedPrimary{background:#db9137 !important;color:#23232D !important;border:2px solid #db9137 !important;}
#protocol .MuiButton-containedPrimary:hover{background:transparent !important;color:#db9137 !important;border:2px solid #db9137 !important;} 
#protocol .MuiButton-containedSecondary{background-color:transparent !important;color:#fff !important;border:2px solid #fff !important;}
#protocol .MuiButton-containedSecondary:hover{background-color:#fff !important;color:#23232D !important;border:2px solid #fff !important;} 

@media only screen and (max-width: 991px){
#protocol{padding-bottom: 100px !important;background-size: 150px;background-position: 95% bottom;}
}
@media only screen and (max-width: 960px){
#protocol{background-image: none;padding-bottom: 20px !important;}
}
@media only screen and (max-width: 767px){
#protocol .protocol-box{padding: 0 0 0;}
#protocol .protocol-banner{margin: 0 0 0;}
#protocol .protocol-text-block ol{padding:15px 0 0 10px;}
#protocol .protocol-text-block ol li{margin:0 0 10px;}
}
@media only screen and (max-width: 599px){

}