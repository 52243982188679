#features{padding:50px 0;background-image: url('../../../assets/images/Grass.png');background-position: 10% bottom;background-repeat: no-repeat;}
#features .section-heading h2{margin:0;}
#features .feature-box{padding:37px 20px;border:1px solid #fff;font-size:16px;line-height:24px;transition: all ease .5s;}
#features .feature-box:hover {box-shadow: 0px 0px 12px 0px #ababab;border-color: transparent;transform: scale(1.05);}
#features .feture-icon-holder{margin:0 0 15px;}
/* #features .MuiButton-root{padding:15px 30px !important;border-radius:8px !important;font-size:24px !important;line-height:27px !important;text-transform: unset !important;} */
/* #features .MuiButton-containedPrimary{background:#db9137 !important;color:#23232D !important;border:2px solid #db9137 !important;} */
/* #features .MuiButton-containedPrimary:hover{background:transparent !important;color:#db9137 !important;border:2px solid #db9137 !important;}  */
/* #features .MuiButton-containedSecondary{background-color:transparent !important;color:#fff !important;border:2px solid #fff !important;} */
/* #features .MuiButton-containedSecondary:hover{background-color:#fff !important;color:#23232D !important;border:2px solid #fff !important;}  */ */

#features .MuiButton-root{padding:15px 30px !important;border-radius:8px !important;font-size:18px !important;line-height:10px !important;text-transform: unset !important;} 
#features .MuiButton-containedPrimary{background:#db9137 !important;color:#23232D !important;border:2px solid #db9137 !important;}
#features .MuiButton-containedPrimary:hover{background:transparent !important;color:#db9137 !important;border:2px solid #db9137 !important;} 
#features .MuiButton-containedSecondary{background-color:transparent !important;color:#fff !important;border:2px solid #fff !important;}
#features .MuiButton-containedSecondary:hover{background-color:#fff !important;color:#23232D !important;border:2px solid #fff !important;} 

@media (max-width: 991px) {
    #features{padding:30px 0; padding-bottom: 100px !important;background-size: 150px;background-position: 5% bottom;}
    #features .feature-box{padding:30px 15px;font-size:15px;line-height:22px;}
}
@media (max-width: 960px) {
    #features .feature-box{
        display: flex;
        align-items: center;
    }
    #features .feature-box .feture-icon-holder {
        margin-bottom: 0;
    }
    #features .feature-box .feture-text-holder {
        text-align: left;
        padding-left: 15px;
    }
    #features .feature-box .feture-text-holder h2 {
        margin-bottom: 10px;
    }
    #features .feature-box .feture-text-holder p {
        margin-bottom: 0;
    }
}

@media (max-width: 767px) {
    #features{padding:25px 0;}
    #features .feature-box{padding:20px 10px;font-size:14px;line-height:20px;}
    #features .feture-icon-holder{margin:0 0 10px;}
    #features .feature-box h2{margin-bottom: 10px;}
    #features .feature-box p{margin-bottom: 10px;}
}
@media (max-width: 599px) {
    #features .feature-box{flex-flow: column;}
    #features .feature-box .feture-icon-holder {
        margin-bottom: 10px;
    }
    #features .feature-box .feture-text-holder {
        text-align: center;
        padding-left: 0;
    }
    #features .feature-box .feture-text-holder h2 {
        margin-bottom: 10px;
    }
    #features .feature-box .feture-text-holder p {
        margin-bottom: 0;
    }
}