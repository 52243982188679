#videos{background: #fff;}
#videos .slick-slider{width: 100%;}
#videos .video-wrapper{padding: 0 15px;width: calc(100% - 30px) !important;}
#videos h3{text-align: center;}
#videos .videos-box iframe{width: 100%;}
.slick-prev, .slick-next{top:55%}
.slick-prev::before, .slick-next::before{color: #000;}
@media (max-width: 599px) {
#videos .video-wrapper {    padding: 0 0;    width: 100% !important;}
#videos .videos-box iframe{height: 250px;}
}