.team-and-advisors{background: #010204;background: -moz-linear-gradient(top,  #010204 0%, #23232d 0%, #23232d 69%, #1e1e27 73%, #050609 97%, #040508 97%, #000000 97%, #000000 100%);background: -webkit-linear-gradient(top,  #010204 0%,#23232d 0%,#23232d 69%,#1e1e27 73%,#050609 97%,#040508 97%,#000000 97%,#000000 100%);background: linear-gradient(to bottom,  #010204 0%,#23232d 0%,#23232d 69%,#1e1e27 73%,#050609 97%,#040508 97%,#000000 97%,#000000 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#010204', endColorstr='#000000',GradientType=0 );}
.team-and-advisors .advisor-block .team-row{border-bottom: none;}
.team-and-advisors .advisor-block .section-heading{margin-top: 50px;}
.team-and-advisors .team-row{padding:40px 24px;border-bottom:1px solid #909095;transition: all ease .5s;}
.team-and-advisors .team-row:hover {box-shadow: 0px 0px 8px 4px #6b6a6a;transform: scale(1.01);}
.team-and-advisors .team-header{margin:0 0 15px;}
.team-and-advisors .team-image{margin:0 15px 15px 0;}
.team-and-advisors .team-detail{font-size:14px; line-height:20px;flex: 1;}
.team-and-advisors .advisor-block .team-detail{padding-right: 20px;}
.team-and-advisors .team-name,
.team-and-advisors .designation{margin:0 10px 0 0;font-size:16px;line-height:19px;}
.team-and-advisors .MuiButton-root{padding:15px 30px !important;border-radius:8px !important;font-size:18px !important;line-height:10px !important;text-transform: unset !important;} 
.team-and-advisors .MuiButton-containedPrimary{background:#db9137 !important;color:#23232D !important;border:2px solid #db9137 !important;}
.team-and-advisors .MuiButton-containedPrimary:hover{background:transparent !important;color:#db9137 !important;border:2px solid #db9137 !important;} 
.team-and-advisors .MuiButton-containedSecondary{background-color:transparent !important;color:#fff !important;border:2px solid #fff !important;}
.team-and-advisors .MuiButton-containedSecondary:hover{background-color:#fff !important;color:#23232D !important;border:2px solid #fff !important;} 

@media (max-width: 991px) {
    .team-and-advisors .team-row{padding:30px 20px;}
    .team-and-advisors .designation{font-size:15px;line-height:18px;}
}
@media (max-width: 767px) {
    .team-and-advisors .advisor-block .section-heading{margin-top: 30px;}
}

@media (max-width: 599px) {
.team-and-advisors .team-image{flex: auto;text-align: center;}
.team-and-advisors .team-detail{flex: auto;text-align: center;}
.team-header-row{justify-content: center;}
}
